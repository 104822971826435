.MuiBox-root{
    max-width: 100%;
}

.heart-container {
    position: relative;
}

.heart-animation {
    position: absolute;
    bottom: 8px;
    left: 50%;
    margin-left: -62px;
    transform: translateX(-50%);
    animation: floatUpAndFadeOut 3s linear forwards;
}

@keyframes floatUpAndFadeOut {
    0% {
        transform: translate(0, 0) rotate(0deg);
        opacity: 1;
    }
    25% {
        transform: translate(20px, -20vh);
    }
    50% {
        transform: translate(-20px, -40vh);
    }
    75% {
        transform: translate(20px, -60vh) scale(2);
    }
    100% {
        transform: translate(-20px, -80vh) rotate(360deg) scale(3);
        opacity: 0;
    }
}